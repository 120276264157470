import '../App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Privacy() {
    return (
      <div className="flex flex-col h-screen justify-between">
        <Header />
        <main className="flex flex-col flex-1 items-center justify-center">
          <div className="flex flex-col items-start">
            <h1 className="self-center">Privacy Policy</h1>
            <h2 className="self-center">Personal Information</h2>
            <p className="text-left">The privacy of your data is important to us which is why we do not collect or share with third parties your personal information.
              Browsing is free of charge. You don’t have to share any personal or sensitive information with us and you don't have to worry about the safeness of your privacy.
            </p>
            <h2 className="self-center">Certain Exceptional Disclosures</h2>
            <p className="text-left">We can make the disclosure of your personal information only in cases stipulated by law or in a court order.</p>
            <p className="text-left">Disclosure of your personal information can be done to protect our legal rights or if the information is a potential threat to the physical safety of any person.</p>
            <h2 className="self-center">General Data Protection Regulation (GDPR)</h2>
            <p className="text-left">We are bound to the strict data protection law. We are committed to comply with the General Data Protection Regulation (GDPR).</p>
            <p className="text-left">The GDPR is a regulation in EU law on data protection and privacy for all individuals across the EU and within the European Economic Area.</p>
            <p className="text-left">SplitAudioFile acts as a data controller when it directly collects or processes personal data providing services to end users. It means that SplitAudioFile acts as a data controller when you upload files, which may contain your personal data.
              This privacy policy explains in detail which data we collect and share. 
              We do collect your IP address, access times, types of the files you convert and an average conversion error rate. 
              We do not share this data with anyone.
            </p>
          </div>
        </main>
        <Footer />
      </div>
    );
}

export default Privacy;