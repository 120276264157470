function Hero() {
    return (
        <div className="text-center text-2xl mb-4 pb-4">
            <h2>Turn Large Audio Files Into Many Small Ones</h2>
            <h4>Split Files By Removing Silences & Receive Results in Zip Folder</h4>
        </div>
    );
  }
  
export default Hero;
