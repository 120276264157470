function Header() {
  return (
    <header style={{backgroundColor: "#005dcc"}} className="p-6">
      <nav className="flex justify-between">
        <a className="text-white text-xl font-bold no-underline flex items-center" href="/">
          <img src="/SplitAudioFile.png" alt="Split Audio File" className="mr-2" style={{width: "50px", height: "50px"}} />
          Split Audio File
        </a>
      </nav>
    </header>
  );
}

export default Header;
