import '../App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Tos() {
    return (
      <div className="flex flex-col h-screen justify-between">
        <Header />
        <main className="flex flex-col flex-1 items-center justify-center text-left">
          <h1>Terms of Service</h1>

          <h2>Introduction</h2>
          <p>These Terms of Service (the "Terms") govern your use of the SplitAudioFile website and services (the "Services"). By accessing and using the Services, you acknowledge and agree to be bound by the following Terms. These Terms apply to the entire website and any API usage provided by SplitAudioFile.</p>

          <h2>Legal Entity and Registered Address</h2>
          <p>The legal entity that provides the Services is SplitAudioFile Limited, a company registered in Cyprus, with its registered address at Krinou 3, Agios Athanasios 4103, Limassol, Cyprus.</p>

          <h2>Privacy Policy and GDPR Compliance</h2>
          <p>We are committed to protecting your privacy and complying with the General Data Protection Regulation (GDPR). For information regarding the collection, use, share and protection of your personal data, please refer to our Privacy Policy located at https://SplitAudioFile.co/privacy/.</p>

          <h2>Service</h2>
          <p>The Service allows you to split audio files into smaller audio files by separating based on silence.</p>

          <h2>Registration and User Conduct</h2>
          <p>You must be at least 13 years old to register for and use the Service. By signing up for the Service, you will create a personalized account. You agree to promptly notify us of any unauthorized use.</p>

          <h2>Use Restrictions</h2>
          <p>Your permission to use the Service is conditioned upon the following Use and Conduct Restrictions.</p>
          <p>You agree that you will not under any circumstances:</p>
          <ul>
          <li>convert any content that is abusive, threatening, obscene, defamatory, libelous, or racially, sexually, religiously, or otherwise objectionable and offensive,</li>
          <li>use the service for any unlawful purposes or to promote illegal activities,</li>
          <li>attempt to harass, abuse, or harm another person or group,</li>
          <li>use another user's account without proper authorization,</li>
          <li>provide false or inaccurate information when registering an account or ordering a premium plan,</li>
          <li>interfere or attempt to interfere with the proper functioning of the Service,</li>
          <li>engage in any automated use of the system or take actions that may impose an unreasonable or disproportionately large load on our servers or network infrastructure,</li>
          <li>bypass any robot exclusion techniques or other measures we may have implemented to restrict access to the Service or use any software, technology, or device to scrape, spider, scan, crawl the Service, or to harvest or manipulate data,</li>
          <li>convert malicious content intended to damage or disrupt the service or pose a risk to another user's browser or computer.</li>
          </ul>

          <h2>Intellectual Property</h2>
          <p>All content and materials on the SplitAudioFile website, including but not limited to text, images, and videos, are the property of SplitAudioFile Limited or its licensors and are protected by copyright law. You may not use, reproduce, distribute, or modify any of this content without the prior written permission of SplitAudioFile Limited.</p>
          <p>It’s strongly prohibited to use the Service and intellectual property for any form of infringement or related actions.</p>

          <h2>User Content</h2>
          <p>You are responsible for all content that you upload to the SplitAudioFile website. You agree not to upload any content that is illegal, offensive, or harmful. We do not supervise User content, but SplitAudioFile Limited reserves the right to remove any content that it deems to be inappropriate.</p>
          <p>The use of our services with the purpose of any unauthorized copying, distribution, as well as any other actions of infringement of copyright law, are expressively prohibited.</p>
          <p>In order to use the Services freely, please, ensure your ownership of the files you process, or gain permission from the legal owner of the files to process them.</p>

          <h2>Liability</h2>
          <p>SplitAudioFile Limited is not liable for any damages that you may suffer as a result of using the Services. This includes, but is not limited to, damages for lost profits, lost data, or damage to your computer system.</p>
          <p>While SplitAudioFile strives to provide a reliable Service, it is your responsibility to maintain backups of your files, since due to privacy concerns we do not store or backup your files, and there is no guarantee that converted files will not be deleted, removed, or lost. SplitAudioFile Limited will not be held responsible for any file conversion failures or loss of data, including any damage resulting from the abovementioned loss. Users are strongly advised to create file archives as well as to backup all files and data.</p>
          <p>You agree to promptly notify us of any unauthorized use of your account. SplitAudioFile Limited is not liable for any losses or damages resulting from the unauthorized use of your login credentials and/or account.</p>

          <h2>Modifications to the Terms</h2>
          <p>SplitAudioFile Limited reserves the right to change these Terms at any time at its sole discretion. The most current version of the Terms will always be available on the SplitAudioFile website.</p>

          <h2>Termination</h2>
          <p>SplitAudioFile Limited may terminate your access to the Services at any time for any reason.</p>

          <h2>Entire Agreement</h2>
          <p>These Terms constitute the entire agreement between you and SplitAudioFile Limited regarding the use of the Services. These Terms supersede any prior or contemporaneous communications, representations, or agreements, whether oral or written.</p>

          <h2>Severability</h2>
          <p>If any provision of these Terms is held to be invalid or unenforceable, such provision will be struck from these Terms and the remaining provisions will remain in full force and effect.</p>

          <h2>Waiver</h2>
          <p>No waiver of any provision of these Terms will be effective unless in writing and signed by both parties.</p>

          <h2>Notices</h2>
          <p>All notices and other communications under these Terms will be in writing and will be deemed to have been duly given when delivered in person, upon the first business day following deposit in the mail (postage prepaid, certified or registered, return receipt requested) addressed as follows:</p>
          <p>2261 Market Street STE 5002 San Francisco, CA 94114</p>

          <h2>Governing Law</h2>
          <p>These Terms are governed by and construed in accordance with the laws of the State of Delaware.</p>
        </main>
        <Footer />
      </div>
    );
}

export default Tos;