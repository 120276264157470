import HomePage from './pages/HomePage';
import Privacy from './pages/Privacy';
import './App.css';
import './styles/tailwind.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Tos from './pages/Tos';

function App() {
  return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/privacy" exact element={<Privacy />} />
            <Route path="/tos" exact element={<Tos />} />
          </Routes>
        </div>
      </Router>
  );
}

export default App;
