function Footer() {
    return (
        <footer style={{backgroundColor: "#005dcc"}} className="p-6">
          <p className="text-center text-white">© 2023 Ruleset Inc. | <a className='no-underline text-white' href="/tos">Terms of Service</a> | <a className='no-underline text-white' href="/privacy">Privacy Policy</a> | contact@splitaudiofile.com
          </p>
        </footer>
    );
}
  
export default Footer;
