import axios from 'axios';

const downloadFile = async (s3Url, filename) => {
  try {
    //console.log('Reached downloadFile');
    //console.log(`Filename: ${filename}`);
    //console.log(`S3 URL: ${s3Url}`);
    const response = await axios({
      url: s3Url,
      method: 'GET',
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'application/octet-stream' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading the file", error);
  }
};

export default downloadFile;
