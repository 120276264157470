import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import AWS from 'aws-sdk';
import axios from 'axios';
import downloadFile from './downloadFile';
import { Spinner } from 'react-bootstrap';
import { usePostHog } from 'posthog-js/react'

const backendUrl = process.env.REACT_APP_FLASK_API_URL;
const bucketUrl = process.env.REACT_APP_S3_BUCKET_NAME;

const handleDownload = (filename) => {
  //console.log('Reached handleDownload');
  //console.log(`Filename: ${JSON.stringify(filename)}`);

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-east-1',
  });

  const downloadParams = {
    Bucket: bucketUrl,
    Key: filename,
    Expires: 60
  };

  const s3Url = s3.getSignedUrl('getObject', downloadParams);
  downloadFile(s3Url, filename);
};

async function uploadToS3(file) {
  //console.log('Reached S3 upload');

  return new Promise((resolve, reject) => {

    const timestamp = Math.floor(Date.now() / 1000);
    let filename = `${file.name.split('.')[0]}_${timestamp}.${file.name.split('.').pop()}`;
    filename = filename.replace(" ", "_")

    const formData = new FormData();
    formData.append('file', file, filename);

    axios
    .post(`${backendUrl}/upload_to_aws`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({data}) => {
      //console.log('Successfully uploaded file:', data);
      resolve(data);
    })
    .catch((error) => {
      //console.log('Error reached');
      //console.log(error);
      reject('Error uploading file');
    });
  });
}

const FileUpload = () => {
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null); // Add this line
  const [selectedOption, setSelectedOption] = useState('mp3'); // Changed this line to set the default value as 'mp3'
  const posthog = usePostHog()

  posthog.capture('user_upload_file');

  useEffect(() => {
    if (isFileUploaded) {
      const timer = setTimeout(() => {
        setIsFileUploaded(false);
      }, 10000); // 10 seconds

      return () => clearTimeout(timer); // This will clear the timer when the component unmounts or when isFileUploaded changes
    }
  }, [isFileUploaded]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      setIsLoading(true);
      setErrorMessage(null); // Reset the error message at the start of a new upload

      let aws_upload_data = {}

      try {
        aws_upload_data = await uploadToS3(acceptedFiles[0]);
      } catch (errorMessage) {
        console.error(errorMessage);
        setIsLoading(false); // Set isLoading to false if there's an error during upload
        setErrorMessage('Error during upload'); // Set the error message here
        return;
      }

      //console.log(`Presigned URL: ${aws_upload_data['audio_s3_url']}`);
      //console.log(`Bucket folder: ${aws_upload_data['bucket_folder']}`);

      axios
        .post(`${backendUrl}/soundsplitter`, 
        {
          presigned_url: aws_upload_data['audio_s3_url'],
          bucket_folder: aws_upload_data['bucket_folder'],
          filename: acceptedFiles[0],
          output_format: selectedOption
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(({data}) => {
          //console.log(data);
          setIsFileUploaded(true);
          //console.log(`Response Data: ${JSON.stringify(data)}`);
          handleDownload(data);
        })
        .catch((error) => {
          //console.log('Error reached');
          //console.log(error);
          setIsLoading(false); // Set isLoading to false if there's an error during upload
          setErrorMessage('Error during audio split. Try again.'); // Set the error message here
        })
        .finally(() => {
          setIsLoading(false); // Set isLoading to false after handleDownload has completed
        });
    },
  });

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div {...getRootProps()} style={{ border: '1px solid black', padding: '20px', marginBottom: '10px' }}>
        <input {...getInputProps({ accept: 'audio/*' })} />
        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <p>
            {errorMessage ? errorMessage : (isFileUploaded ? "File Successfully Uploaded!" : "Drop file here, or click to select file")}
          </p>
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label style={{ marginRight: '10px', fontSize: '20px' }}>Output Format:</label>
        <select onChange={handleSelectChange} style={{ backgroundColor: '#bcccdc', padding: '10px', width: '200px', height: '40px', fontSize: '18px' }}>
          <option value="mp3">MP3</option>
          <option value="wav">WAV</option>
          <option value="flac">FLAC</option>
        </select>
      </div>
    </div>
  );
};

export default FileUpload;