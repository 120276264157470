import React, { useState } from 'react';
import '../App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
//import UploadWithOutputFormat from '../components/UploadWithOutputFormat';
import FileUpload from '../components/FileUpload';
import Hero from '../components/Hero';

function HomePage() {
  return (
      <div className="flex flex-col h-screen justify-between">
        <Header />
        <main className="flex flex-col flex-1 items-center justify-center">
          <Hero />
          <FileUpload />
        </main>
        <Footer />
      </div>
    );
}

export default HomePage;